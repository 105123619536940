import { useEffect, useState } from "react";
import Loader from "../components/Loader/Loader";
import MaintenanceNotice from "../pages/MaintenanceNotice/MaintenanceNotice";

export const DisplaySpinner: React.FC<{ isSocketLive: boolean }> = ({
  isSocketLive,
}) => {
  const [isError, setIsError] = useState(false);
  const timeout = 10 * 1000;

  useEffect(() => {
    if (!isSocketLive) {
      const timer = setTimeout(() => {
        setIsError(true);
      }, timeout);

      return () => clearTimeout(timer);
    } else {
      setIsError(false);
    }
  }, [isSocketLive, timeout]);

  return <> {!isError ? <Loader /> : <MaintenanceNotice />} </>;
};
