import React, { createContext, useState, useEffect } from "react";
import { Socket, io } from "socket.io-client";
import { usePageLoading } from "../hooks/usePageLoading";
import { DisplaySpinner } from "./DisplaySpinner";

type WebSocketContextType = {
  webSocket: Socket | null;
  isSocketLive: boolean;
};

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

export const WebSocketProvider: React.FC<{
  children: React.ReactNode;
  url: string;
  user_id: number | undefined;
  user_username: string | undefined;
  user_firstname: string | undefined;
  user_lastname: string | undefined;
  initData: string | undefined;
}> = ({
  children,
  url,
  user_id,
  user_username,
  user_firstname,
  user_lastname,
  initData,
}) => {
  const [webSocket, setWebSocket] = useState<Socket | null>(null);
  const [isSocketLive, setIsSocketLive] = useState<boolean>(true);
  const { setPageLoading } = usePageLoading();

  useEffect(() => {
    const socket = io(url, {
      reconnectionDelayMax: 10000,
      query: {
        user_id: user_id,
        user_username: user_username,
        user_firstname: user_firstname,
        user_lastname: user_lastname,
        initData: initData,
      },
    });

    setWebSocket(socket);
    setPageLoading(true);

    socket.on("disconnect", () => {
      console.log("Socket disconnected");

      setIsSocketLive(false);
      setPageLoading(false);
    });

    socket.on("connect_error", (error) => {
      console.log("Socket connection error", error);

      setIsSocketLive(false);
      setPageLoading(false);
    });

    socket.on("connect", () => {
      console.log("Socket connected");

      setIsSocketLive(true);
      setPageLoading(false);
    });

    return () => {
      socket.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ webSocket, isSocketLive }}>
      {isSocketLive ? children : <DisplaySpinner isSocketLive={isSocketLive} />}
    </WebSocketContext.Provider>
  );
};
