import "./App.css";
import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WebSocketProvider } from "./contexts/WebsocketContext";
import { getConfig } from "./utils/config";
import { getTelegramUser } from "./services/telegramService";
import Loader from "./components/Loader/Loader";
import { UserProvider } from "./contexts/UserContext";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { LoadingProvider } from "./contexts/LoadingContext";
import { ToastProvider } from "./contexts/ToastProvider";

const Layout = lazy(() => import("./pages/Layout/Layout"));
const Home = lazy(() => import("./pages/Home/Home"));
const Quiz = lazy(() => import("./pages/Quiz/Quiz"));
const Tasks = lazy(() => import("./pages/Tasks/Tasks"));
const Leaderboard = lazy(() => import("./pages/Leaderboard/Leaderboard"));
const Club = lazy(() => import("./pages/Quizzes/Club"));
const ReputationApp = lazy(() => import("./pages/ReputationApp/ReputationApp"));
const Friends = lazy(() => import("./pages/Friends/Friends"));
const UserProfile = lazy(
  () => import("./pages/Profile/UserProfile/UserProfile")
);
const OtherProfile = lazy(
  () => import("./pages/Profile/OthersProfile/OtherProfile")
);
const MaintenanceNotice = lazy(
  () => import("./pages/MaintenanceNotice/MaintenanceNotice")
);
const AdminRoute = lazy(() => import("./pages/Admin/AdminRoute"));
const AdminSettings = lazy(
  () => import("./pages/Admin/AdminSettings/AdminSettings")
);
const AdminTasks = lazy(() => import("./pages/Admin/AdminTasks/AdminTasks"));
const AddTask = lazy(() => import("./pages/Admin/AddTask/AddTask"));
const AdminUsers = lazy(() => import("./pages/Admin/AdminUsers/AdminUsers"));
const NoPage = lazy(() => import("./pages/NoPage/NoPage"));

function switchSwipeDown(enable: boolean) {
  window.Telegram.WebApp.expand();
  window.Telegram.WebApp.disableVerticalSwipes();

  window.addEventListener("scroll", (e) => {
    e.preventDefault();
  });

  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
  });
}

// function verifyDevice(platform: string): void {
//   if (platform === "tdesktop")
//     alert("This app is not available for desktop. Please use a mobile device");
//   setTimeout(() => {
//     window.Telegram.WebApp.close();
//   }, 3 * 1000);
// }

function App() {
  const { serverUrl, adminPassword } = getConfig();
  const telegramUser = getTelegramUser();

  useEffect(() => {
    switchSwipeDown(true);
    // verifyDevice(platform); // Verify device and close the app if it's on desktop
  }, []);
  return (
    <BrowserRouter>
      <TonConnectUIProvider manifestUrl="https://my-bot-app.ngrok.app/tonconnect-manifest.json">
        <LoadingProvider>
          <WebSocketProvider
            url={serverUrl}
            user_id={telegramUser?.id}
            user_firstname={telegramUser?.first_name}
            user_username={telegramUser?.username}
            user_lastname={telegramUser?.last_name}
            initData={telegramUser?.initData}
          >
            <UserProvider user_id={telegramUser.id}>
              <ToastProvider>
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route
                      path="/"
                      element={<Layout />}
                      loader={() => <Loader />}
                    >
                      <Route index element={<Home />} />
                      <Route path="quiz" element={<Quiz />} />
                      <Route path="tasks" element={<Tasks />} />
                      <Route path="board" element={<Leaderboard />} />
                      <Route path="club" element={<Club />} />
                      <Route path="app" element={<ReputationApp />} />
                      <Route path="friends" element={<Friends />} />
                      <Route path="user-profile" element={<UserProfile />} />
                      <Route path="other-profile" element={<OtherProfile />} />
                      <Route
                        path="maintenance-notice"
                        element={<MaintenanceNotice />}
                      />
                    </Route>
                    <Route
                      path="/admin"
                      element={<AdminRoute password={adminPassword} />}
                    >
                      <Route index element={<AdminSettings />} />
                      <Route path="tasks" element={<AdminTasks />} />
                      <Route path="tasks/add" element={<AddTask />} />
                      <Route path="users" element={<AdminUsers />} />
                      <Route path="settings" element={<AdminSettings />} />
                    </Route>
                    <Route path="*" element={<NoPage />} />
                  </Routes>
                </Suspense>
              </ToastProvider>
            </UserProvider>
          </WebSocketProvider>
        </LoadingProvider>
      </TonConnectUIProvider>
    </BrowserRouter>
  );
}

export default App;
